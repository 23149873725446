import {ref} from 'vue'
import {axiosInstance as axios} from '@/plugins/axios.js'

const groups = ref([])
const loading = ref(false)

export const useGroup = () => {
	const fetchGroup = (companyId) => {
		loading.value = true
		// v1/corporates/${user.value.id}/groups
		axios.get(`/v1/corporates/${companyId}/groups`)
        .then((res) => {
		  if(res.data.data) {
			groups.value = res?.data?.data
		  } else {
			groups.value = []
		  }
        })
        .catch((error) => {
			Swal.fire({ icon: 'error', title: 'Error', text: error?.response?.data?.message})
        })
        .finally(() => {
			loading.value = false
        })
	}

	return { groups, loading, fetchGroup}
}