import {ref} from 'vue'
import Swal from 'sweetalert2'
import {axiosInstance as axios} from '@/plugins/axios.js'

const corporatePaySettings = {
    book_on_corporate_routes: ref(true),
    book_on_public_routes: ref(true),
    booking_limits_active: ref(true),
    corporate_id: ref(),
    default_corporate_payment_limit: {
        id: ref(),
        limit_type: ref('none'),
        limit_value: ref(0),
        limit_value_unit: ref('none'),
    },
    exemptions: ref([]),
    id: ref(),
    staff_can_view_wallet_limit_usage : ref()
}

const isEditingExemption = ref(false)
const exemption_obj = {
    type: ref('amount'),
    book_or_amount_limit: ref(),
    select_period: ref(''),
    subject_ids: ref(''),
    subject_type: ref('')
}

const clear_exemption_obj = () => {
    exemption_obj.book_or_amount_limit.value = undefined
    exemption_obj.select_period.value = ''
    exemption_obj.subject_ids.value = undefined
    exemption_obj.subject_type.value = ''
}

const getPeriod = (str) => {
    if (str == 'per_day') {
        return 'day'
    } else if(str == 'per_week') {
        return 'week'
    }
    else if(str == 'per_month') {
        return 'month'
    }else {
        return 'year'
    }
}

const getExemptionLimitWriteUp = (data) => {
    if(data.limit_type == 'trip') {
        return `Allow an employee book ${data.limit_value} trips per ${getPeriod(data.limit_value_unit)}`
    } else {
        return `Allow an employee spend ₦${data.limit_value} per ${getPeriod(data.limit_value_unit)}`
    }
}


export const useCorporatePaySetting = () => {
    const loading = ref(false)
    const fetchCorporatePaySetting = (companyId) => {
        loading.value = true
		axios.get(`/v1/corporates/${companyId}/payment-settings`)
		.then((res) => {
			const data = res?.data
			corporatePaySettings.book_on_corporate_routes.value = data.book_on_corporate_routes
			corporatePaySettings.book_on_public_routes.value = data.book_on_public_routes
			corporatePaySettings.booking_limits_active.value = data.booking_limits_active
			corporatePaySettings.corporate_id.value = data.corporate_id
			corporatePaySettings.staff_can_view_wallet_limit_usage.value = data.staff_can_view_wallet_limit_usage == 1 ? true : false
			corporatePaySettings.default_corporate_payment_limit.id.value = data.default_corporate_payment_limit?.id
			corporatePaySettings.default_corporate_payment_limit.limit_type.value = data.default_corporate_payment_limit?.limit_type
			corporatePaySettings.default_corporate_payment_limit.limit_value.value = data.default_corporate_payment_limit?.limit_value
			corporatePaySettings.default_corporate_payment_limit.limit_value_unit.value = data.default_corporate_payment_limit?.limit_value_unit
			corporatePaySettings.exemptions.value = data.exemptions
			corporatePaySettings.id.value = data.id
			loading.value = false
		})
		.catch((err) => {
			Swal.fire({ icon: 'error', title: 'Error', text: err?.response?.data?.message ?? 'An error occured'})
			loading.value = false
		})
    }

    const saveCorporatePaySettings = (companyId, val) => {
        loading.value = true
		axios.patch(`/v1/corporates/${companyId}/payment-settings`, {
			...val
		})
		.then((res) => {
			loading.value = false
			if (res?.data?.id) {
				Swal.fire({ icon: 'success', title: 'Success', text: 'Settings saved successfully'})
			} else {
				Swal.fire({ icon: 'error', title: 'Error', text: res?.data?.message || 'Unable to save settings'})
			}
			fetchCorporatePaySetting(companyId)
		})
		.catch((err) => {
			Swal.fire({ icon: 'error', title: 'Error', text: err?.response?.data?.message ?? 'An error occured'})
			loading.value = false
		})
    }

	const updateExemptions = (companyId) => {
        loading.value = true
		axios.patch(`/v1/corporates/${companyId}/payment-settings`, {
            exemptions: [
                {
                    limit_type: exemption_obj.type.value,
                    limit_value: exemption_obj.book_or_amount_limit.value,
                    limit_value_unit: exemption_obj.select_period.value,
                    subject_id: exemption_obj.subject_ids.value,
                    subject_type: exemption_obj.subject_type.value
                }
            ]
        })
        .then((res) => {
            loading.value = false
            if (res?.data?.id) {
                isEditingExemption.value = false
                Swal.fire({ icon: 'success', title: 'Success', text: 'Settings saved successfully'})
                clear_exemption_obj()
            } else {
                Swal.fire({ icon: 'error', title: 'Error', text: res?.data?.message || 'Unable to save settings'})
            }
            fetchCorporatePaySetting(companyId)
        })
        .catch((err) => {
            loading.value = false
            Swal.fire({ icon: 'error', title: 'Error', text: err?.response?.data?.message ?? 'An error occured'})
        })
    }

    const deleteExemption = (companyId,id) => {
        loading.value = true
		axios.delete(`/v1/corporate-payment-limitations/${id}`)
        .then((res) => {
            loading.value = false
            if (res?.data?.success) {
				Swal.fire({ icon: 'success', title: 'Success', text: 'Exemption deleted successfully'})
            } else {
				Swal.fire({ icon: 'error', title: 'Error', text: res?.data?.message || 'Unable to delete exemption'})
            }
            fetchCorporatePaySetting(companyId)
        })
        .catch((err) => {
            loading.value = false
            Swal.fire({ icon: 'error', title: 'Error', text: err?.response?.data?.message ?? 'An error occured'})
        })
    }

    const editExemption = (data) => {
        exemption_obj.type.value = data.limit_type
        exemption_obj.book_or_amount_limit.value = data.limit_value
        exemption_obj.select_period.value = data.limit_value_unit
        exemption_obj.subject_type.value = data.subject_type
        exemption_obj.subject_ids.value = data.subject_id
        isEditingExemption.value = true
    }

    const toggleStaffsAbilityToViewCorporatePayUsage = () => {
        corporatePaySettings.staff_can_view_wallet_limit_usage.value = !corporatePaySettings.staff_can_view_wallet_limit_usage.value
    }

    return { ...corporatePaySettings, corporatePaySettings, loading, fetchCorporatePaySetting, saveCorporatePaySettings,
		isEditingExemption, exemption_obj, clear_exemption_obj, getExemptionLimitWriteUp, 
		updateExemptions, deleteExemption, editExemption, toggleStaffsAbilityToViewCorporatePayUsage
	}
}

