import {ref} from 'vue'
import {axiosInstance as axios} from '@/plugins/axios.js'

const staffs = ref([])
const loading = ref(false)

export const useStaff = () => {
	const fetchStaff = (companyId) => {
		loading.value = true
		axios.get(`/v1/corporates/${companyId}/staff`)
        .then((res) => {
		  if(res.data.data) {
			staffs.value = res?.data?.data
		  } else {
			staffs.value = []
		  }
        })
        .catch((error) => {
			Swal.fire({ icon: 'error', title: 'Error', text: error?.response?.data?.message || 'An error occured'})
        })
        .finally(() => {
			loading.value = false
        })
	}

	return { staffs, loading, fetchStaff}
}

export const useAllStaffWithoutPagination = () => {
	const fetchAllStaffWithoutPagination = (companyId) => {
		loading.value = true
		axios.get(`/v1/corporates/${companyId}/staff?limit=${10}&page=${1}`)
        .then((res) => {
		  const total = res.data.metadata.total
		  axios.get(`/v1/corporates/${companyId}/staff?limit=${total}&page=${1}`)
		  .then((res) => {
			if(res.data.data.length) {
				staffs.value = res.data.data
			} else {
				staffs.value = []
			}
			loading.value = false
		  })
        })
        .catch((error) => {
			Swal.fire({ icon: 'error', title: 'Error', text: error?.response?.data?.message || 'An error occured'})
        })
        .finally(() => {
			loading.value = false
        })
	}

	return { staffs, loading, fetchAllStaffWithoutPagination}
}